.rtg__modal {
  background: #fff;
  border-radius: 5px;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
  left: 50%;
  max-width: 90vw;
  min-width: 300px;
  padding: 40px 20px 20px;
  position: absolute;
  top: 50vh;
  transition-duration: 0.8s;
  transition-property: top, left, scale;
}
.rtg__modal--hidden {
  display: none;
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}
.rtg__modal--visible {
  display: block;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
.rtg__modal:after {
  background: inherit;
  content: '';
  position: absolute;
  z-index: -1;
}
.rtg__modal--top-center:after {
  bottom: calc(var(--arrow-size) * -1 + 1px);
  -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  display: block;
  height: var(--arrow-size);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: calc(var(--arrow-size) * 2);
}
.rtg__modal--top-right:after {
  bottom: calc(var(--arrow-size) * -1);
  -webkit-clip-path: polygon(0 100%, 50% 0, 100% 50%);
  clip-path: polygon(0 100%, 50% 0, 100% 50%);
  display: block;
  height: calc(var(--arrow-size) * 2);
  left: calc(var(--arrow-size) * -1);
  width: calc(var(--arrow-size) * 2);
}
.rtg__modal--top-left:after {
  bottom: calc(var(--arrow-size) * -1);
  -webkit-clip-path: polygon(50% 0, 100% 100%, 0 50%);
  clip-path: polygon(50% 0, 100% 100%, 0 50%);
  display: block;
  height: calc(var(--arrow-size) * 2);
  right: calc(var(--arrow-size) * -1);
  width: calc(var(--arrow-size) * 2);
}
.rtg__modal--bottom-center:after {
  -webkit-clip-path: polygon(0 100%, 50% 0, 100% 100%);
  clip-path: polygon(0 100%, 50% 0, 100% 100%);
  display: block;
  height: var(--arrow-size);
  left: 50%;
  top: calc(var(--arrow-size) * -1 + 1px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: calc(var(--arrow-size) * 2);
}
.rtg__modal--center-right:after {
  -webkit-clip-path: polygon(100% 0, 0 50%, 100% 100%);
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  display: block;
  height: calc(var(--arrow-size) * 2);
  left: calc(var(--arrow-size) * -1 + 1px);
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: var(--arrow-size);
}
.rtg__modal--center-left:after {
  -webkit-clip-path: polygon(0 0, 100% 50%, 0 100%);
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  display: block;
  height: calc(var(--arrow-size) * 2);
  right: calc(var(--arrow-size) * -1 + 1px);
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: var(--arrow-size);
}
.rtg__modal--bottom-left:after {
  -webkit-clip-path: polygon(0 50%, 100% 0, 50% 100%);
  clip-path: polygon(0 50%, 100% 0, 50% 100%);
  display: block;
  height: calc(var(--arrow-size) * 2);
  right: calc(var(--arrow-size) * -1);
  top: calc(var(--arrow-size) * -1);
  width: calc(var(--arrow-size) * 2);
}
.rtg__modal--bottom-right:after {
  -webkit-clip-path: polygon(0 0, 50% 100%, 100% 50%);
  clip-path: polygon(0 0, 50% 100%, 100% 50%);
  display: block;
  height: calc(var(--arrow-size) * 2);
  left: calc(var(--arrow-size) * -1);
  top: calc(var(--arrow-size) * -1);
  width: calc(var(--arrow-size) * 2);
}
body {
  position: relative;
}
.rtg__backdrop {
  border-radius: 5px;
  box-shadow: 0 0 0 1000vh rgba(0, 0, 0, 0.5);
  pointer-events: none;
  position: absolute;
}
.rtg__backdrop--visible {
  display: block;
}
.rtg__backdrop--hidden {
  display: none;
}
body {
  counter-reset: rtg_selector;
}
.rtg__control {
  -webkit-align-self: flex-end;
  align-self: flex-end;
  display: -webkit-flex;
  display: flex;
  margin-top: 24px;
}
.rtg__button,
.rtg__control {
  -webkit-justify-content: center;
  justify-content: center;
}
.rtg__button {
  -webkit-align-items: center;
  align-items: center;
  border: none;
  color: var(--primary-color);
  display: -webkit-inline-flex;
  display: inline-flex;
  margin: 0 2px;
  outline: none;
  padding: 0;
}
.rtg__button:focus {
  outline: 1px solid rgba(0, 0, 0, 0.1);
}
.rtg__button:disabled {
  cursor: not-allowed;
}
.rtg__button svg {
  height: 30px;
  width: 30px;
}
.rtg__button--prev svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rtg__button--selector {
  position: relative;
}
.rtg__button--selector svg {
  height: 20px;
  width: 20px;
}
.rtg__button--selector:before {
  -webkit-align-items: center;
  align-items: center;
  bottom: 28px;
  content: counter(rtg_selector);
  counter-increment: rtg_selector;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  opacity: 0;
  position: absolute;
  width: 20px;
}
.rtg__button--selector:hover:before {
  opacity: 0.5;
}
.rtg__button--hide-before:before {
  display: none;
}
.rtg__badge {
  background: var(--primary-color);
  border-radius: 4px;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  left: -6px;
  min-width: 60px;
  padding: 2px 6px;
  position: absolute;
  top: -6px;
}
.rtg__badge span {
  font-size: 0.9rem;
}
.rtg__badge--waiting {
  -webkit-animation: blinking;
  animation: blinking;
  animation-direction: reverse;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
@-webkit-keyframes blinking {
  0% {
    background: var(--primary-color);
  }
  to {
    background: var(--wating-color);
  }
}
@keyframes blinking {
  0% {
    background: var(--primary-color);
  }
  to {
    background: var(--wating-color);
  }
}
.rtg__pin {
  border: none;
  border-radius: 50%;
  height: 20px;
  left: -100px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -100px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 20px;
}
.rtg__pin,
.rtg__pin:focus {
  outline: none;
}
.rtg__pin:hover span {
  display: block;
}
.rtg__pin span {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  color: var(--primary-color);
  display: none;
  left: 10px;
  padding: 4px 6px;
  position: absolute;
  top: -40px;
  white-space: nowrap;
}
.rtg__pin:before {
  -webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1)
    infinite;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  background-color: var(--pin-color);
  border-radius: 45px;
  box-sizing: border-box;
  content: '';
  display: block;
  height: 300%;
  margin-left: -100%;
  margin-top: -100%;
  position: relative;
  width: 300%;
}
.rtg__pin:after {
  -webkit-animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s
    infinite;
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@-webkit-keyframes pulse-ring {
  0% {
    -webkit-transform: scale(0.33);
    transform: scale(0.33);
  }
  80%,
  to {
    opacity: 0;
  }
}
@keyframes pulse-ring {
  0% {
    -webkit-transform: scale(0.33);
    transform: scale(0.33);
  }
  80%,
  to {
    opacity: 0;
  }
}
@-webkit-keyframes pulse-dot {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
@keyframes pulse-dot {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
.rtg__button--close {
  color: var(--primary-color);
  position: absolute;
  right: 10px;
  top: 10px;
}
.rtg__button--close svg {
  height: 25px;
  width: 25px;
}
.rtg__container {
  --primary-color: #0b708b;
  --wating-color: #c00021;
  --pin-color: #0fa9d0;
  --arrow-size: 15px;
}
.rtg__container * {
  box-sizing: border-box;
}
